import React from "react";
import { ICardItem } from "../../interfaces/ICardItem";
import "./projects.styles.css";

const CardItem: React.FC<ICardItem> = ({
  title,
  date,
  description,
  imageAuthor,
  url,
  softwareType,
  technology,
  githubRepo,
}) => {
  return (
    <div className="card border-dark card-holder">
      <img
        className="card-img-top card-image"
        src={url}
        alt="Card"
        title={`Author: ${imageAuthor}`}
      />

      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{description}</p>
        <ul className="card-detail-list">
          <li>
            <span>Software: {softwareType}</span>
          </li>
          <li className="pt-2">
            <span>Tech Stack: {technology}</span>
          </li>
          {githubRepo && (
            <li className="pt-2">
              <a href={githubRepo} target="_blank" rel="noreferrer">
                GitHub
              </a>
            </li>
          )}
        </ul>
      </div>
      <div className="card-footer border-dark">
        <small className="text-muted">
          {date
            ? `Last contribution: ${date?.toDateString()}`
            : "Under-development"}
        </small>
      </div>
    </div>
  );
};

export default CardItem;

import React from "react";
import "./page404.styles.css"

const Page404 = () => {
  return (
    <div className="container not-found-container">
      <h1>404 😒</h1>
    </div>
  );
};

export default Page404;

import React from "react";
import CardItem from "./card-item";
import "./projects.styles.css";

const Projects = () => {
  return (
    <div className="container pt-5">
      <div className="project-card-container">
        <CardItem
          imageAuthor="erik-mclean"
          url="https://andres-cv-bucket.s3.amazonaws.com/service.jpg"
          title="Sin-Servicio"
          description="Check for water, internet, power problems or cuts near you"
          softwareType={"Mobile App"}
          technology="React Native"
        />
        <CardItem
          imageAuthor="priscilla-du-preez"
          title="Eventor"
          description="Log recent events or information near you"
          url="https://andres-cv-bucket.s3.amazonaws.com/evento.jpg"
          softwareType={"Mobile App"}
          technology="React Native"
        />
        <CardItem
          imageAuthor="markus-spiske-iar"
          url="https://andres-cv-bucket.s3.amazonaws.com/pcrypt.jpg"
          title="PCrypt"
          description="Encrypt and decrypt text files"
          date={new Date(2022, 0, 23)}
          softwareType={"Console App"}
          technology="C#"
          githubRepo="https://github.com/avc01/PCrypt"
        />
        <CardItem
          imageAuthor="nataliya-melnychuk"
          url="https://andres-cv-bucket.s3.amazonaws.com/skin.jpg"
          title="SkinCrabApp"
          description="Mobile app for skin protection"
          date={new Date(2021, 11, 18)}
          softwareType={"Mobile App"}
          technology="Xamarin"
          githubRepo="https://github.com/avc01/SkinCrabApp"
        />
        <CardItem
          imageAuthor="lukasz-niescioruk"
          url="https://andres-cv-bucket.s3.amazonaws.com/autoradar.jpg"
          title="AutoRadar"
          description="Website for car selling"
          date={new Date(2022, 3, 29)}
          softwareType={"Web App"}
          technology="Angular & NodeJs"
          githubRepo="https://github.com/Gabuardi/auto-radar"
        />
      </div>
    </div>
  );
};

export default Projects;

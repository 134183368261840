export const timelineData = [
  {
    title: "September 2024",
    cardTitle: "College Done",
    cardDetailedText: `I graduated from college as a Software Engineer`,
  },
  {
    title: "May 2024",
    cardTitle: "Completed Docker/Kubernetes Course",
    cardSubtitle: "Docker & Kubernetes: The Practical Guide [2024 Edition]",
  },
  {
    title: "April 2024",
    cardTitle: "Completed Python Course",
    url: "https://www.udemy.com/certificate/UC-21ff2ab1-5cce-45c0-8731-589c942283ce",
    cardSubtitle: "100 Days of Code: The Complete Python Pro Bootcamp",
    cardDetailedText: `This course was a challenge due to its duration, but was definitely worth it`,
  },
  {
    title: "August 2023",
    cardTitle: "AWS Cloud Practitioner",
    url: "https://www.credly.com/badges/4cd9e281-4d7b-4be9-af32-a1aeb966915e/linked_in_profile",
    cardSubtitle: `AWS Certified Cloud Practitioner`,
    cardDetailedText: `This was my intro to AWS, I really enjoyed it and it also help me with my professional career`,
  },
  {
    title: "November 2022",
    cardTitle: "Completed Typescript Course",
    url: "https://www.udemy.com/certificate/UC-c29600b5-950c-45e8-8e11-6f0de58a9a44/",
    cardSubtitle: `Typescript: The Complete Developer's Guide`,
    cardDetailedText: `Another good course, great complement to React and it also provided great guidelines to develop professional UI projects`,
  },
  {
    title: "August 2022",
    cardTitle: "Completed React Js Course",
    url: "https://www.udemy.com/certificate/UC-f82b9454-f3a4-4773-bd4a-a5005f7a3756/",
    cardSubtitle: `Complete React Developer in 2022 by ZTM Academy`,
    cardDetailedText: `This was such a great course, I learned a lot and it also help me with my job and personal projects | 100% Recommended`,
  },
  {
    title: "July 2021",
    cardTitle: "Intern/Junior Software Engineer",
    cardDetailedText: `I joined GFT Technologies as a Intern and then became a Junior Software Engineer`,
  },
  {
    title: "May 2019",
    cardTitle: "I Started College",
    cardDetailedText: `I started college at "Universidad Latinoamericana de Ciencia y Tecnologia"`,
  },
];

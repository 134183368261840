import React from "react";
import { ISideMenu } from "../../interfaces/ISideMenu";
import $ from "jquery";
import "./side-menu.styles.css";
import { Link, Outlet } from "react-router-dom";

const SideMenu: React.FC<ISideMenu> = () => {
  const toggleMenuHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    $("#sidebar").toggleClass("active");
  };

  const downloadPdf = () => {
    var link = document.createElement("a");
    link.href = "andres-viquez-cv.pdf";
    link.download = `andres-viquez-cv-${new Date().getFullYear()}.pdf`;
    link.dispatchEvent(new MouseEvent("click"));
  };

  return (
    <div className="wrapper">
      {/* <!-- Sidebar  --> */}
      <nav id="sidebar">
        <div className="sidebar-header">
          <h3>Andrés Víquez</h3>
          <h6>Software Engineer</h6>
        </div>

        <ul className="list-unstyled components">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/learning-path">Learning Path</Link>
          </li>
          <li>
            <Link to="/projects">Projects</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>

        <ul className="list-unstyled CTAs">
          <li>
            <a
              href="https://andres-cv-bucket.s3.amazonaws.com/andres-viquez-cv.pdf"
              className="view"
              target="_blank"
              rel="noreferrer"
            >
              View CV
            </a>
          </li>
          <li>
            <a href="#" className="download" onClick={downloadPdf}>
              Download CV
            </a>
          </li>
        </ul>

        <ul className="list-social-links">
          <li>
            <a
              href="https://www.linkedin.com/in/andr%C3%A9s-v%C3%ADquez-chavarr%C3%ADa-b62a021b4/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
          </li>
          <li>
            <a href="https://github.com/avc01" target="_blank" rel="noreferrer">
              GitHub
            </a>
          </li>
        </ul>
      </nav>

      {/* <!-- Page Content  --> */}
      <div id="content">
        <button
          type="button"
          id="sidebarCollapse"
          className="btn btn-dark menu-btn"
          onClick={(e) => toggleMenuHandler(e)}
        >
          <i className="fa fa-bars" /> Menu
        </button>

        <Outlet />
      </div>
    </div>
  );
};

export default SideMenu;

import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { IContact } from "../../interfaces/IContact";
import "./contact.styles.css";

type ContactForm = {
  from_name?: string;
  message?: string;
  mail?: string;
};

const formDefaultState: ContactForm = {
  from_name: "",
  mail: "",
  message: "",
};

const Contact: React.FC<IContact> = () => {
  const [form, setForm] = useState<ContactForm>(formDefaultState);
  const [mailSent, setMailSent] = useState<boolean>(false);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    emailjs
      .send(
        "service_h1u53lh",
        "template_9yuhikk",
        {
          to_name: "Andres",
          from_name: form?.from_name,
          message: form?.message,
          reply_to: form?.mail,
        },
        "l3ToyMgki_zLgFliA"
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setMailSent(true);
          setTimeout(() => setMailSent(false), 3000);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );

    setForm(formDefaultState);
  };

  return (
    <div className="container pt-5 contact-container">
      <div className="card rounded">
        <div className="card-header">
          <h1>Contact</h1>
        </div>

        <div className="card-body">
          <form onSubmit={(e) => sendEmail(e)}>
            <div className="form-group">
              <label>Name</label>
              <input
                required
                className="form-control"
                type="text"
                name="from_name"
                value={form?.from_name}
                onChange={(e) =>
                  setForm({ ...form, from_name: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                required
                className="form-control"
                type="email"
                name="mail"
                value={form?.mail}
                onChange={(e) => setForm({ ...form, mail: e.target.value })}
              />
            </div>

            <div className="form-group">
              <label>Message</label>
              <textarea
                rows={5}
                maxLength={400}
                required
                className="form-control"
                name="message"
                value={form?.message}
                onChange={(e) => setForm({ ...form, message: e.target.value })}
              />
            </div>

            <input
              type="submit"
              className="btn btn-primary send-btn"
              value="Send"
            />
            {mailSent && (
              <span className="badge badge-success ml-3">
                Message sent succesfully!
              </span>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import { ILearningPath } from "../../interfaces/ILearningPath";
import { Chrono } from "react-chrono";
import { timelineData } from "./timeline-data";

const LearningPath: React.FC<ILearningPath> = () => {
  return (
    <div className="container-md pt-5">
      <Chrono
        mode="VERTICAL"
        items={timelineData}
        theme={{
          cardBgColor: "#393E46",

          cardTitleColor: "#fff",
          cardSubtitleColor: "#B4B4B8",
          cardDetailsColor: "#B4B4B8",

          primary: "#D65A31",
          secondary: "#D65A31",
          titleColorActive: "#222831",
          titleColor: "#222831",

          iconBackgroundColor: "#fff",
        }}
        cardHeight={150} // sets the height of the timeline card to 200px
        readMore={true} // enables the readMore function for larger chunks of text
        timelinePointShape="diamond"
        timelinePointDimension={20}
      />
    </div>
  );
};

export default LearningPath;

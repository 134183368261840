import React from "react";
import { IHome } from "../../interfaces/IHome";
import "./home.styles.css";

const Home: React.FC<IHome> = () => {
  return (
    <div className="container home-container">
       <h1>Home Page is Under Construction! 🏗️</h1>
    </div>
  );
};

export default Home;

import React from "react";
import { IApp } from "./interfaces/IApp";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./modules/home/home";
import SideMenu from "./modules/menu/side-menu";
import Contact from "./modules/contact/contact";
import LearningPath from "./modules/learning-path/learning-path";
import Page404 from "./modules/404/page404";
import Projects from "./modules/projects/projects";
import Health from "./modules/healthcheck/health";

const App: React.FC<IApp> = () => {
    return (
        <BrowserRouter>
            <Routes>
            <Route path="/" element={<SideMenu />}>
                <Route index element={<Home />} />
                <Route path="contact" element={<Contact />} />
                <Route path="learning-path" element={<LearningPath />} />
                <Route path="projects" element={<Projects/>} />
                <Route path="health" element={<Health/>} />
                <Route path="*" element={<Page404/>} />
            </Route>
            </Routes>
        </BrowserRouter>
    )
} 

export default App;
